import React from 'react'
import { Button, Icon, Modal, Grid, Divider } from 'semantic-ui-react'
import SwiperCore, { Pagination, Mousewheel, A11y, Autoplay } from 'swiper'

import SEO from '../../components/SEO'
import Vimeo from '../../components/Vimeo'

import Logo from '../../images/logos/contego-logo.png'
import { callHandler } from '../../ultilities'

SwiperCore.use([Pagination, A11y, Mousewheel, Autoplay])

const TechBenefits = () => {
  const handleCallNow = e => {
    const currentHour = Number(
      new Date()
        .toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour12: false })
        .split(':')[0]
    )

    if (currentHour >= 8 && currentHour <= 21) {
      callHandler(e.currentTarget.dataset.phoneNumber)
    } else {
      // eslint-disable-next-line no-alert
      window.alert(
        'Liên lạc ngoài giờ làm việc!!! Anh chị vui lòng để lại thông tin bên dưới, nhân viên tư vấn sẽ liên lạc. Giờ làm việc của nhân viên tư vấn 8AM-9PM (Giờ California)'
      )
    }
  }

  return (
    <>
      <div className="container new-font">
        <SEO
          title="Lợi Ích Của Thợ Nail Khi Dùng Ghế Contego?"
          description="Công nghệ tiên tiến của ghế CONTEGO pedicure spa chair bảo vệ đôi tay chăm chỉ không biết mệt mỏi của những thợ nail"
          meta={[
            {
              name: 'keywords',
              content:
                'GIÁ TRỊ ghế Contégo, lợi ích ghế Contego, hữu ích, lợi ích, ghế Contego, thợ nail, bảo vệ chính mình'
            }
          ]}
        />

        <Grid columns="equal" className="py-4" stackable>
          <Grid.Column className="d-flex flex-wrap">
            <img
              src={Logo}
              width="auto"
              height="auto"
              alt="contego company logo"
              className="mr-4"
            />
            <h1 className="my-auto py-2">TIỆN ÍCH DÀNH CHO THỢ NAIL KHI DÙNG GHẾ CONTEGO</h1>
          </Grid.Column>
        </Grid>

        <div className="row rounded py-2">
          <div className="col">
            <div className="bg-light">
              <h2 className="text-secondary text-center px-4 py-2">
                <sup>
                  <Icon size="small" name="quote left" />
                </sup>
                Cái ghế này hoàn toàn PERFECT, rất SMART cho người nhân viên
                <sup>
                  <Icon size="small" name="quote right" />
                </sup>
              </h2>
            </div>
          </div>
        </div>

        <div>
          <div className="row py-2">
            <div className="col py-4">
              <h3 className="mb-1">JET-LINER thay thế MAGNETIC-JET truyền thống</h3>
              <p>
                <strong className="text-secondary">Không tốn Thời Gian và Sức Lực</strong> Vệ Sinh
                Jet, <strong className="text-secondary">Không Lo Mất Jet.</strong>
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Vimeo
                id={662098416}
                title="Tiện ích của việc thay thế magnetic jet truyền thống bằng Contego Jet-liner"
                frameRatio={1}
                threshold={[0, 1]}
              />
            </div>
          </div>
        </div>

        <Divider />

        <div>
          <div className="row py-2">
            <div className="col py-4">
              <h3 className="mb-1">CÔNG NGHỆ OVERFLOW (CHỐNG TRÀN TỰ ĐỘNG)</h3>
              <p>Thoải mái châm nước mà không sợ nước tràn.</p>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Vimeo
                id={662098405}
                title="Thoải mái châm nước mà không sợ nước tràn."
                frameRatio={1}
                threshold={[0, 1]}
              />
            </div>
          </div>
        </div>

        <Divider />

        <div>
          <div className="row py-2">
            <div className="col py-4">
              <h3 className="mb-1">EASY DRAIN</h3>
              <p>Tiện lợi xả nước trong bồn mà không sợ nước dơ vào cổ tay.</p>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Vimeo
                id={662098392}
                title="Tiện lợi xả nước trong bồn mà không sợ nước dơ vào cổ tay."
                frameRatio={1}
                threshold={[0, 1]}
              />
            </div>
          </div>
        </div>

        <Divider />

        <div>
          <div className="row py-2">
            <div className="col py-4">
              <h3 className="mb-0">Và Nhiều Tính Năng Khác</h3>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Vimeo
                id={662098381}
                title="Và Nhiều Tính Năng Khác"
                frameRatio={1}
                threshold={[0, 1]}
              />
            </div>
          </div>
        </div>

        <h2 className="text-blue">
          Hãy chia sẽ NGAY HÔM NAY những công nghệ mới và hữu ích này để được làm việc trên ghế
          CONTEGO <strong className="text-secondary">AN TOÀN</strong>,{' '}
          <strong className="text-secondary">TIỆN LỢI</strong> và{' '}
          <strong className="text-secondary">SẠCH SẼ</strong> hơn trong tiệm của mình.
        </h2>

        <div
          className="fb-share-button"
          data-href="https://contegospa.com/vi/techbenefits"
          data-layout="button_count"
          data-size="large"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcontegospa.com%2Fvi%2Ftechbenefits&amp;src=sdkpreparse"
            className="fb-xfbml-parse-ignore"
          >
            Share
          </a>
        </div>
        <span className="px-2" />
        <Button
          className="call-now-btn rounded-pill text-white shadow-sm"
          data-phone-number="7148958865"
          onClick={handleCallNow}
        >
          GỌI NGAY <Icon name="phone" className="m-0" />
        </Button>
      </div>
      <br />
      <br />
      <br />
    </>
  )
}

export default TechBenefits
